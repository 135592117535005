import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c('app-card-actions',{on:{"refresh":_vm.fetchItems}},[_c('template',{slot:"title"},[_vm._v(" Item List ")]),_c(VCardText,{staticClass:"d-flex align-center flex-wrap pb-0"},[_c(VSpacer),_c('div',{staticClass:"d-flex align-center pb-5"},[_c(VTextField,{attrs:{"single-line":"","dense":"","outlined":"","hide-details":"","placeholder":"Search"},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c(VSelect,{attrs:{"label":"Status","items":_vm.$store.state.status['items'],"item-text":"title","item-value":"value","outlined":"","dense":"","hide-details":"","clearable":""},model:{value:(_vm.statusFilter),callback:function ($$v) {_vm.statusFilter=$$v},expression:"statusFilter"}})],1)],1),_c(VDataTable,{attrs:{"headers":_vm.tableColumns,"items":_vm.ItemsListTable,"item-key":"Id","items-per-page":10,"options":_vm.options,"loading":_vm.loading,"sort-desc":[true],"expanded":_vm.expanded,"show-expand":""},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.Id",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(item.Id)+" ")]}},{key:"item.Name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[_c('vc-image',{attrs:{"width":32,"height":32,"object-id":item.Id,"table-name":"Item","label-text":item.Name}})],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_vm._v(" "+_vm._s(item.Name)+" ")])],1)]}},{key:"item.Category",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Category.Name)+" ")]}},{key:"item.Price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Price == 0 ? 'Price On Selection' : item.Price.toFixed(3))+" JD ")]}},{key:"item.Status",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.$store.state.status['items'].find(function (obj) {
                return obj.value == item.Status
              }).variant || 'primary') + "--text"),attrs:{"small":"","color":_vm.$store.state.status['items'].find(function (obj) {
                return obj.value == item.Status
              }).variant || 'primary'}},[_vm._v(" "+_vm._s(item.Status)+" ")])]}},{key:"item.Actions",fn:function(ref){
              var item = ref.item;
return [_c('vc-change-status',{attrs:{"ObjectId":item.Id,"TableName":"items","WithDialog":true,"Status":item.Status == 'active' ? 'inactive' : 'active'},on:{"refetch-data":_vm.fetchItems}})]}},{key:"expanded-item",fn:function(ref){
              var headers = ref.headers;
              var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(true)?_c(VRow,[_c(VCard,{attrs:{"outlined":""}},[_c(VCardTitle,[_c(VRow,[_c(VCol,{attrs:{"cols":12}},[_c(VDataTable,{attrs:{"headers":_vm.expandedColumns,"items":_vm.fil(item.Addons),"item-key":"Id","items-per-page":10},scopedSlots:_vm._u([{key:"item.Id",fn:function(ref){
              var item = ref.item;
return [_vm._v(" #"+_vm._s(item.Id)+" ")]}},{key:"item.Name",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.Name)+" ")]}},{key:"item.Status",fn:function(ref){
              var item = ref.item;
return [_c(VRow,[_c(VCol,{attrs:{"cols":"3"}},[_c(VChip,{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.$store.state.status['options'].find(function (obj) {
                  return obj.value == item.Status
                }).variant || 'primary') + "--text"),attrs:{"small":"","color":_vm.$store.state.status['options'].find(function (obj) {
                  return obj.value == item.Status
                }).variant || 'primary'}},[_vm._v(" "+_vm._s(item.Status)+" ")])],1),_c(VCol,{attrs:{"cols":"6"}},[_c('vc-change-status',{attrs:{"ObjectId":item.Id,"TableName":"options","WithDialog":true,"Status":item.Status == 'active' ? 'inactive' : 'active'},on:{"refetch-data":_vm.fetchItems}})],1)],1)]}}],null,true)})],1)],1)],1)],1)],1):_vm._e()],1)]}}],null,true)})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }