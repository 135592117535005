import request from '@core/utils/RequestApi'
import qs from 'qs'
export function GetByCustomerIdRestaurantId(data) {
  return request({
    url: '/Order/GetByCustomerIdRestaurantId',
    method: 'get',
    params: data,
  })
}
export function GetByCustomerIdAndBranchId(data) {
  return request({
    url: '/Orders/GetByCustomerIdAndBranchId',
    method: 'get',
    params: data,
  })
}
export function GetByBranchId(data) {
  return request({
    url: '/Order/GetByBranchId',
    method: 'get',
    params: data,
  })
}
export function GetByAddresses(data) {
  return request({
    url: '/Orders/GetByAddresses',
    method: 'get',
    params: data,
  })
}
export function GetByUserId(data) {
  return request({
    url: '/Order/GetByUserId',
    method: 'get',
    params: data,
  })
}
export function GetForAdmin(data) {
  return request({
    url: '/Order/GetForAdmin',
    method: 'get',
    params: data,
  })
}
export function GetForOwner(data) {
  return request({
    url: '/Order/GetForOwner',
    method: 'get',
    params: data,
  })
}
export function GetForAgent(data) {
  return request({
    url: '/Order/GetForAgent',
    method: 'get',
    params: data,
  })
}
export function Create(data) {
  return request({
    url: '/Order/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}

export function Edit(data) {
  return request({
    url: '/Order/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Delete(data) {
  return request({
    url: '/Order/Delete',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Get(data) {
  return request({
    url: '/Order/Get',
    method: 'get',
    params: data,
  })
}
export function ChangeStatus(data) {
  return request({
    url: '/Order/ChangeStatus',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function GetStatusFinished(data) {
  return request({
    url: '/Order/GetStatusFinished',
    method: 'get',
    params: data,
  })
}
export function GetSchedule(data) {
  return request({
    url: '/Order/GetSchedule',
    method: 'get',
    params: data,
  })
}
