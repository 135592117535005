import request from '@core/utils/RequestApi'
import qs from 'qs'

export function CreateOption(data) {
  return request({
    url: '/Option/CreateOption',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Edit(data) {
  return request({
    url: '/Option/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function GetRestaurantOptions(data) {
  return request({
    url: '/Option/GetRestaurantOptions',
    method: 'get',
    params: data,
  })
}
export function AddBranchOption(data) {
  return request({
    url: '/Option/AddBranchOption',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function ChangeStatusOption(data) {
  return request({
    url: '/Option/ChangeStatusOption',
    method: 'post',
    data: qs.stringify(data),
  })
}
