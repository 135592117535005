import request from '@core/utils/RequestApi'
import qs from 'qs'

export function Create(data) {
  return request({
    url: '/Item/Create',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Edit(data) {
  return request({
    url: '/Item/Edit',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function Delete(data) {
  return request({
    url: '/Item/Delete',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function GetByBranchId(data) {
  return request({
    url: '/Item/GetByBranchId',
    method: 'get',
    params: data,
  })
}
export function CheckStatus(data) {
  return request({
    url: '/Item/CheckStatus',
    method: 'get',
    params: data,
  })
}
export function ChangeStatus(data) {
  return request({
    url: '/Item/ChangeStatus',
    method: 'post',
    data: qs.stringify(data),
  })
}
export function GetInActiveByBranch(data) {
  return request({
    url: '/Item/GetInActiveByBranch',
    method: 'get',
    params: data,
  })
}
export function RemoveAddon(data) {
  return request({
    url: '/Item/RemoveAddon',
    method: 'post',
    data: qs.stringify(data),
  })
}

