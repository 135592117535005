import { GetByBranchId } from '@core/api/Items'
import { ref, watch } from '@vue/composition-api'

export default function useItemList() {
  const tableColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Name', value: 'Name' },
    { text: 'Category', value: 'Category' },
    { text: 'Price', value: 'Price' },
    { text: 'Description', value: 'Description', sortable: false },
    { text: 'Status', value: 'Status', sortable: false },
    { text: 'Actions', value: 'Actions', sortable: false },
  ]

  const ItemsListTable = ref([])
  const searchQuery = ref('')
  const statusFilter = ref(null)
  const totalItemsListTable = ref(0)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })
  const loading = ref(false)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*
  const expanded = ref([])
  const fetchItems = () => {
    const BranshId = JSON.parse(localStorage.getItem('userData')).BranshId
    GetByBranchId({ BranchId: BranshId, Any: searchQuery.value, Status: statusFilter.value })
      .then(response => {
        // handle success
        ItemsListTable.value = response
        totalItemsListTable.value = ItemsListTable.lenght
        loading.value = false
      })
      .catch(error => {
        loading.value = false

        console.log(error)
      })
  }
  watch([searchQuery, statusFilter, options], () => {
    loading.value = true

    fetchItems()
  })
  return {
    expanded,
    fetchItems,
    ItemsListTable,
    tableColumns,
    searchQuery,
    statusFilter,
    totalItemsListTable,
    options,
    loading,
  }
}
