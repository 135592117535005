<template>
  <v-row>
    <v-col cols="12">
      <!-- Main Card -->
      <app-card-actions @refresh="fetchItems">
        <template slot="title"> Item List </template>

        <!-- search -->
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-spacer></v-spacer>
          <div class="d-flex align-center pb-5">
            <v-text-field
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search"
            ></v-text-field>

            <v-select
              v-model="statusFilter"
              label="Status"
              :items="$store.state.status['items']"
              item-text="title"
              item-value="value"
              outlined
              dense
              hide-details
              clearable
            ></v-select>
          </div>
        </v-card-text>
        <!-- table -->

        <v-data-table
          :headers="tableColumns"
          :items="ItemsListTable"
          item-key="Id"
          :items-per-page="10"
          :options.sync="options"
          :loading="loading"
          :sort-desc="[true]"
          :expanded.sync="expanded"
          show-expand
        >
          <!-- Id -->
          <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
          <!-- name -->
          <template #[`item.Name`]="{ item }">
            <div class="d-flex align-center">
              <v-avatar
                :color="item.avatar ? '' : 'primary'"
                :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
                size="32"
              >
                <vc-image :width="32" :height="32" :object-id="item.Id" table-name="Item" :label-text="item.Name" />
              </v-avatar>
              <div class="d-flex flex-column ms-3">
                {{ item.Name }}
              </div>
            </div>
          </template>
          <!-- Category -->
          <template #[`item.Category`]="{ item }" style="align: 'start'"> {{ item.Category.Name }} </template>
          <!-- Price -->
          <template #[`item.Price`]="{ item }" style="align: 'start'">
            {{ item.Price == 0 ? 'Price On  Selection' : item.Price.toFixed(3) }} JD
          </template>

          <!-- Status -->
          <template #[`item.Status`]="{ item }">
            <v-chip
              small
              :color="
                $store.state.status['items'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              "
              :class="`${
                $store.state.status['items'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              }--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.Status }}
            </v-chip>
          </template>
          <!-- Actions -->
          <template #[`item.Actions`]="{ item }">
            <vc-change-status
              @refetch-data="fetchItems"
              :ObjectId="item.Id"
              TableName="items"
              :WithDialog="true"
              v-bind:Status="item.Status == 'active' ? 'inactive' : 'active'"
            />
          </template>
          <template #expanded-item="{ headers, item }">
            <td :colspan="headers.length">
             <v-row v-if="true">
              <v-card outlined>
                <v-card-title>
                  <v-row>
                    <v-col :cols="12">
                      <v-data-table
                      :headers="expandedColumns"
                      :items="fil(item.Addons)"
                       item-key="Id"
                       :items-per-page="10"
                       >
                      <template #[`item.Id`]="{ item }" style="align: 'start'"> #{{ item.Id }} </template>
                      <template #[`item.Name`]="{ item }" style="align: 'start'"> {{ item.Name }} </template>
                      <template #[`item.Status`]="{ item }">
              <v-row>
                <v-col cols="3">
              <v-chip
                small
                :color="
                  $store.state.status['options'].find(obj => {
                    return obj.value == item.Status
                  }).variant || 'primary'
                "
                :class="`${
                  $store.state.status['options'].find(obj => {
                    return obj.value == item.Status
                  }).variant || 'primary'
                }--text`"
                class="v-chip-light-bg font-weight-semibold text-capitalize"
              >
                {{ item.Status }}
              </v-chip>
              </v-col>
              <v-col cols="6">
              <vc-change-status
                @refetch-data="fetchItems"
                :ObjectId="item.Id"
                TableName="options"
                :WithDialog="true"
                v-bind:Status="item.Status == 'active' ? 'inactive' : 'active'"
            />
            </v-col>
            </v-row>
            </template>
                       </v-data-table>
                    </v-col>
                  </v-row>
                </v-card-title>
              </v-card>
              </v-row>
              </td>
            </template>
        </v-data-table>
      </app-card-actions>
    </v-col>
  </v-row>
</template>
<script>
import AppCardActions from '@/@core/components/app-card-actions/AppCardActions.vue'
import VcImage from '@/components/vc-avatar/VcImage.vue'
import VcChangeStatus from '@/components/vc-status/vc-change-status.vue'
import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencilOutline, mdiPlus, mdiTrendingUp } from '@mdi/js'

import useItemList from './useItemList.js'
export default {
  components: {
    AppCardActions,
    VcImage,
    VcChangeStatus,
  },
  setup() {
    const {
      ItemsListTable,
      tableColumns,
      searchQuery,
      totalItemsListTable,
      options,
      statusFilter,
      fetchItems,
      expanded,
      loading,
    } = useItemList()
    const expandedColumns = [
    { text: 'Id', value: 'Id' },
    { text: 'Name', value: 'Name', sortable: false },
    { text: 'Price', value: 'Price', sortable: false },
    { text: 'Status', value: 'Status', sortable: false },

  ]
  const isdep = (item) => {
      let c = item.filter(f => f.IsDependence == true)
      console.log('ccccccc', c)
      return c
    }
    const fil = (item) => {
    let isdepvar = isdep(item)
    if(isdepvar.length > 0){

      let op = isdepvar.map(x => {
        return {
         OptionAddons: x.OptionAddons.map(l => {
           return {
            Id : l.Id,
            Name : l.Name,
            Price : l.Price,
            Status : l.Status,
           }
         })
        }
      })
      let obj = op[0].OptionAddons
      console.log('isdepvar', obj)
      return obj

    }
  }
    return {
      fil,
      isdep,
      expandedColumns,
      expanded,
      ItemsListTable,
      tableColumns,
      searchQuery,
      totalItemsListTable,
      options,
      statusFilter,
      fetchItems,
      loading,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
