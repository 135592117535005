<template>
  <div>
    <v-btn
    v-if="WithDialog"
      :color="
        $store.state.status['' + TableName + ''].find(obj => {
          return obj.value == Status
        }).variant || 'primary'
      "
      @click="isSnackbarVisible = true"
    >
      {{
        $store.state.status['' + TableName + ''].find(obj => {
          return obj.value == Status
        }).title
      }}
    </v-btn>
    <v-btn
    v-if="!WithDialog"
      :color="
        $store.state.status['' + TableName + ''].find(obj => {
          return obj.value == Status
        }).variant || 'primary'
      "
      @click="onSubmit"
    >
      {{
        $store.state.status['' + TableName + ''].find(obj => {
          return obj.value == Status
        }).title
      }}
    </v-btn>
    <v-snackbar :timeout="5000" v-model="isSnackbarVisible" vertical :light="$vuetify.theme.dark" top>
      Are sure you wanna make {{ TableName }} {{ ObjectId }} {{ Status }} ?
      <template #action="{ attrs }">
        <v-btn color="success" text v-bind="attrs" @click="onSubmit"> Do it </v-btn>
        <v-btn color="error" text v-bind="attrs" @click="isSnackbarVisible = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar absolute right v-model="isSnackbarOnSubmitVisible" :timeout="1000" :light="$vuetify.theme.dark " top>
      Done
    </v-snackbar>
    <!-- <v-snackbar centered v-model="isCancelled" :timeout="-1" color="error" elevation="24"  rounded="pill">

    </v-snackbar> -->
    <v-dialog
      v-model="isCancelled"
      persistent
      max-width="290"
    >
      <v-card flat tile  class="d-flex justify-center "><v-card-title class="text-h5">
          .لقد تم الغاء هذا الطلب
        </v-card-title>
      </v-card>
      <v-card tile class="d-flex">
        <v-card-text>This Order Has Been Cancelled, And Cannot Be Accepted.
        </v-card-text></v-card>
      <v-card tile class="d-flex ">
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="changing()"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ChangeStatus as ChangeStatusBranch } from '@/@core/api/Branch'
import { ChangeStatus as ChangeStatusItem } from '@/@core/api/Items'
import { ChangeStatusOption } from '@/@core/api/Options'
import { ChangeStatus as ChangeStatusOrder } from '@/@core/api/Order'

import { ref } from '@vue/composition-api'

export default {
  props: {
    ObjectId: {
      type: Number,
      required: true,
    },
    TableName: { type: String, required: true },
    Status: {
      type: String,
      required: true,
    },
    WithDialog: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isSnackbarVisible = ref(false)
    const isCancelled = ref(false)
    const isSnackbarOnSubmitVisible = ref(false)
    const onSubmit = () => {
      switch(props.TableName) {
        case 'orders':
        ChangeStatusOrder({ Id: props.ObjectId, Status: props.Status }).then(res => {
          if (res == 'cancelled') {
            isCancelled.value = true
            //location.reload()
          }
            else if (res === true) {
              isSnackbarVisible.value = false
              isSnackbarOnSubmitVisible.value = true
              emit('refetch-data')
             // location.reload()
            }
          else {
            isSnackbarVisible.value = false
            emit('refetch-data')
           // location.reload()
          }
        })
        break;
        case 'items':
        ChangeStatusItem({ Id: props.ObjectId, Status: props.Status }).then(res => {
          if (res) {
            isSnackbarVisible.value = false
            isSnackbarOnSubmitVisible.value = true
            emit('refetch-data')
           // location.reload()
          } else {
            isSnackbarVisible.value = false
          }
        })
        break;
        case 'branches':
        ChangeStatusBranch({ Id: props.ObjectId, Status: props.Status }).then(res => {
          if (res) {
            isSnackbarVisible.value = false
            isSnackbarOnSubmitVisible.value = true
            emit('refetch-data')
           // location.reload()
          } else {
            isSnackbarVisible.value = false
          }
        })
        break;
        case 'options':
        ChangeStatusOption({ Id: props.ObjectId, Status: props.Status }).then(res => {
          if (res) {
            isSnackbarVisible.value = false
            isSnackbarOnSubmitVisible.value = true
            emit('refetch-data')
           // location.reload()
          } else {
            isSnackbarVisible.value = false
          }
        })
        break;
}
    }
    const changing = () => {
        isCancelled.value = false;
      //  location.reload()
      }
    return { changing, onSubmit, isSnackbarVisible, isSnackbarOnSubmitVisible, isCancelled }
  },
}
</script>
